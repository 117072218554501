.posts{
    flex: 9;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

@media (min-width: 1024px){
    .posts{
        margin: 20px;
    }
}