.newAuthor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 5% auto 5%;
}

.newAuthor > p {
    padding: 10px;
    font-family: "Varela Round", "Nunito", sans-serif;
    white-space: pre-line;
}

@media (min-width: 1024px) {
    .newAuthor {
        height: calc(100vh - 50px);
        margin: auto 35% auto 35%;
    }
}