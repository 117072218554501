.login{
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("./../../assets/images/pexels-element-digital-1470170.jpg");
    background-size: cover;
}

.loginTitle{
    font-size: 50px;
}

.loginForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm > label{
    margin: 10px 0;
}

.loginInput{
    padding: 10px;
    border-color: white;
    border: none;
}

.loginButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.loginButton:disabled{
    cursor: not-allowed;
    background-color: rgb(241, 159, 159);
}

.loginRegisterButton{
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: teal;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}