.home{
    display: flex;
}

.sidebar{
    display: none;
}

.pagination {
	display: flex;
    justify-content: center;
	list-style: none;
    padding-bottom: 50px;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #444;
	border-color: #444
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6
}

.page-link {
    padding: .475rem .75rem;
    cursor: pointer;
	position: relative;
	display: block;
	color: #444;
	text-decoration: none;
	background-color: #fdfbfb;
	border: 1px solid #dee2e6;
    border-radius: 3px;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.page-link:hover {
	z-index: 2;
	color: #444;
	background-color: #e9ecef;
	border-color: #dee2e6
}

@media (min-width: 1024px){
    .sidebar{
        display: inline;
    }
	.pagination{
		justify-content: left;
		margin-left: 30%;
	}
}