.register{
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("./../../assets/images/pexels-element-digital-1470170.jpg");
    background-size: cover;
}

.registerTitle{
    font-size: 50px;
}

.registerForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.registerForm > label{
    margin: 10px 0;
}

.registerInput{
    padding: 10px;
    border-color: white;
    border: none;
}

.registerButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.registerLoginButton{
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}