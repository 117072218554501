.single{
    display: flex;
}

.sidebar{
    display: none;
}


@media (min-width: 1024px){
    .sidebar{
        display: inline;
    }
}