.about {
    margin: 20px 5% auto 5%;
}

.about > img {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.about > p {
    padding: 10px;
    font-family: "Varela Round", "Nunito", sans-serif;
    white-space: pre-line;
}

@media (min-width: 1024px) {
    .about {
        margin: 20px 30% auto 30%;
    }

    .about > img {
        float: right;
        width: 300px;
        margin: 20px;
    }
}
