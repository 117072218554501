.footer {
    padding-top: 20px;
    margin: 0 15% 20px 15%;
    font-family: "Varela Round", "Nunito", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top: 1px solid #a7a4a4;
}

@media (min-width: 1024px) {
    .footer {
        justify-content: left;
        padding-left: 17%;
    }
}
